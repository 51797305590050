/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
// import { useLocation } from "react-router";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import SVG from "react-inlinesvg";
import {
  // FormattedMessage,
  injectIntl,
} from "react-intl";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { shallowEqual, useSelector } from "react-redux";
import * as TYPE from "../../../../../app/lib/contrant";

function AsideMenuList(props) {
  const { listUserLoginPermission } = useSelector(
    (state) => ({
      listUserLoginPermission: state.authReducer.listUserLoginPermission,
    }),
    shallowEqual
  );

  let { layoutProps, intl } = props;
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/* Dashboard management */}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")} />
            </span>
            <span className="menu-text">
              {intl.formatMessage({ id: "SIDEBAR.DASHBOARD" })}
            </span>
          </NavLink>
        </li>

        {<li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/admin",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/admin">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.ADMIN_MANAGEMENT' })}</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.PERMISSION_LIST' })}</span>
                </span>
              </li>
              {<li
                className={`menu-item ${getMenuItemActive(
                  "/admin/list-user"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/admin/list-user">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.ADMIN' })}</span>
                </NavLink>
              </li>}
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>}
        {<li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/task",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/Task">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.TASK_MANAGEMENT' })}</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.TASK_MANAGEMENT' })}</span>
                </span>
              </li>
              {<li
                className={`menu-item ${getMenuItemActive(
                  "/task/list"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/task/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.TASK_LIST' })}</span>
                </NavLink>
              </li>}
              {
                  <li
                    className={`menu-item ${getMenuItemActive("/task/group-collab/list")}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/task/group-collab/list">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        {intl.formatMessage({ id: "COLLAB.TITLE" })}
                      </span>
                    </NavLink>
                  </li>
                }
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>}
        {<li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/user",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/user">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.USER_MANAGEMENT' })}</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.USER_MANAGEMENT' })}</span>
                </span>
              </li>
              {<li
                className={`menu-item ${getMenuItemActive(
                  "/user/list"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/user/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.USER_LIST' })}</span>
                </NavLink>
              </li>}
              {/* {<li
                className={`menu-item ${getMenuItemActive(
                  "/user/checkin"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/user/checkin">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{intl.formatMessage({ id: 'CHECKIN.TITLE' })}</span>
                </NavLink>
              </li>} */}
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>}
        {<li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/team",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/team">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.MANAGE_LEADERBOARD' })}</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.MANAGE_LEADERBOARD' })}</span>
                </span>
              </li>
              {<li
                className={`menu-item ${getMenuItemActive(
                  "/team/list"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/team/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.TEAM_LIST' })}</span>
                </NavLink>
              </li>}
              {<li
                className={`menu-item ${getMenuItemActive(
                  "/team/daily"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/team/daily">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{intl.formatMessage({ id: 'DAILY.TITLE' })}</span>
                </NavLink>
              </li>}
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>}
        {<li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/asset",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/asset">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.ASSETS_MANAGEMENT' })}</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.ASSETS_MANAGEMENT' })}</span>
                </span>
              </li>
              {<li
                className={`menu-item ${getMenuItemActive(
                  "/assets/list"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/assets/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.ASSETS_MANAGEMENT' })}</span>
                </NavLink>
              </li>}
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>}

        {/* <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/daily-code",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/daily-code">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")} />
            </span>
            <span className="menu-text">
              {intl.formatMessage({ id: "SIDEBAR.DAILY_CODE_MANAGEMENT" })}
            </span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">
                    {intl.formatMessage({
                      id: "SIDEBAR.DAILY_CODE_MANAGEMENT",
                    })}
                  </span>
                </span>
              </li>
              {
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/daily-code/list"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/daily-code/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      {intl.formatMessage({
                        id: "SIDEBAR.DAILY_CODE_MANAGEMENT",
                      })}
                    </span>
                  </NavLink>
                </li>
              }
              {
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/daily-code/completed"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/daily-code/completed">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      {intl.formatMessage({
                        id: "SIDEBAR.TELEGRAM_DAILY_CODE_LIST",
                      })}
                    </span>
                  </NavLink>
                </li>
              }
            </ul>
          </div>
        </li> */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/lucky-draw",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/lucky-draw">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")} />
            </span>
            <span className="menu-text">
              {intl.formatMessage({ id: "SIDEBAR.LUCKY_DRAW" })}
            </span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">
                    {intl.formatMessage({ id: "SIDEBAR.LUCKY_DRAW" })}
                  </span>
                </span>
              </li>
              {
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/lucky-draw/list"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/lucky-draw/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      {intl.formatMessage({ id: "SIDEBAR.LUCKY_DRAW" })}
                    </span>
                  </NavLink>
                </li>
              }
            </ul>
          </div>
        </li>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/daily-question",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/lucky-draw">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")} />
            </span>
            <span className="menu-text">
              {intl.formatMessage({ id: "DAILY_QUESTION.MENU" })}
            </span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">
                    {intl.formatMessage({ id: "DAILY_QUESTION.MENU" })}
                  </span>
                </span>
              </li>
              {
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/daily-question/question"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/daily-question/question">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      {intl.formatMessage({ id: "DAILY_QUESTION.QUESTION" })}
                    </span>
                  </NavLink>
                </li>
              }
              {
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/daily-question/list"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/daily-question/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      {intl.formatMessage({ id: "DAILY_QUESTION.TITLE" })}
                    </span>
                  </NavLink>
                </li>
              }
            </ul>
          </div>
        </li>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/ticket-film",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/lucky-draw">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")} />
            </span>
            <span className="menu-text">
              {intl.formatMessage({ id: "TICKET_FILM.MENU" })}
            </span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">
                    {intl.formatMessage({ id: "TICKET_FILM.MENU" })}
                  </span>
                </span>
              </li>
              {
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/ticket-film/list"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/ticket-film/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      {intl.formatMessage({ id: "TICKET_FILM.TITLE" })}
                    </span>
                  </NavLink>
                </li>
              }
            </ul>
          </div>
        </li>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/ticket-prize",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/ticket-prize">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")} />
            </span>
            <span className="menu-text">
              {intl.formatMessage({ id: "Quản lý duyệt vé" })}
            </span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">
                    {intl.formatMessage({ id: "Quản lý duyệt vé" })}
                  </span>
                </span>
              </li>
              {
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/ticket-prize/list"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/ticket-prize/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      {intl.formatMessage({ id: "Danh sách yêu cầu nhận vé" })}
                    </span>
                  </NavLink>
                </li>
              }
            </ul>
          </div>
        </li>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/config",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/config">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: 'CONFIG.TITLE' })}</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{intl.formatMessage({ id: 'CONFIG.TITLE' })}</span>
                </span>
              </li>
              {<li
                className={`menu-item ${getMenuItemActive(
                  "/config/address-ton"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/config/address-ton">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{intl.formatMessage({ id: 'CONFIG.TON_ADDRESS' })}</span>
                </NavLink>
              </li>}
            </ul>
          </div>
        </li>
      </ul>
    </>
  );
}
export default withRouter(injectIntl(AsideMenuList));
