import { Types } from "./types";

const initialState = {
  dataFilmTicket: {
    paging: {},
    rows: [],
  },
  dataOrder: {
    paging: {},
    rows: []
  }
};
const filmTicketReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case Types.SAVE_LIST_FILM_TICKET:
      return {
        ...newState,
        dataFilmTicket: action.payload,
      };
    case Types.SAVE_LIST_ORDER:
      return {
        ...newState,
        dataOrder: action.payload,
      };
    default:
      return { ...newState };
  }
};
export default filmTicketReducer;
