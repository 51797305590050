import { Types } from './types'

const initialState = {
    dataQuestion: {
        paging: {},
        rows: []
    },

    dataDailyQuestion: {
        paging: {},
        rows: []
    }
}
const questionReducer = (state = initialState, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case Types.SAVE_LIST_QUESTION:
            return {
                ...newState,
                dataQuestion: action.payload
            }
        case Types.SAVE_LIST_DAILY_QUESTION:
            return {
                ...newState,
                dataDailyQuestion: action.payload
            }
        default:
            return { ...newState }
    }
}
export default questionReducer
