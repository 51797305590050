import { Types } from "./type";

const initialState = {
  dataTeam: {
    rows: [],
    paging: {},
  },
  detailTeam: {
    paging: {},
    rows: [],
  },
  dataDay: {
    rows: [],
    paging: {},
  },
  detailDay: {
    rows: [],
    paging: {},
  },
};
const teamReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case Types.SAVE_LIST_TEAM:
      return {
        ...newState,
        dataTeam: action.payload,
      };
    case Types.SAVE_DETAIL_TEAM:
      return {
        ...newState,
        detailTeam: action.payload,
      };
    case Types.SAVE_LIST_DAY:
      return {
        ...newState,
        dataDay: action.payload,
      };
    case Types.SAVE_DETAIL_DAY:
      return {
        ...newState,
        detailDay: action.payload,
      };
    default:
      return { ...newState };
  }
};
export default teamReducer;
