import { Types } from "./types";

const initialState = {
  tonAddress: {},
};
const configReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case Types.SAVE_TON_ADDRESS:
      return {
        ...newState,
        tonAddress: action.payload,
      };
    default:
      return { ...newState };
  }
};
export default configReducer;
