import { Types } from "./types";

const initialState = {
  dataTaskCollab: {
    rows: [],
    paging: {},
  },
  dataGroupTaskCollab: {
    rows: [],
    paging: {},
  },
  detailGroupCollab: {}
};
const taskCollabReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case Types.SAVE_LIST_TASK_COLLAB:
      return {
        ...newState,
        dataTaskCollab: action.payload,
      };
    case Types.SAVE_LIST_GROUP_TASK_COLLAB:
      return {
        ...newState,
        dataGroupTaskCollab: action.payload,
      };
    case Types.SAVE_DETAIL_GROUP_COLLAB:
      return {
        ...newState,
        detailGroupCollab: action.payload,
      };
    default:
      return { ...newState };
  }
};
export default taskCollabReducer;
