import { Types } from "./types";

const initialState = {
  dataRankConfig: {
    paging: {},
    rows: [],
    detailSeason: {}
  },
  dataSeasonRank: {
    paging: {},
    rows: [],
  },
};
const rankConfigReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case Types.SAVE_LIST_RANK_CONFIG:
      return {
        ...newState,
        dataRankConfig: action.payload,
      };
    case Types.SAVE_LIST_SEASON_RANK:
      return {
        ...newState,
        dataSeasonRank: action.payload,
      };
    default:
      return { ...newState };
  }
};
export default rankConfigReducer;
